import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { useWebPSupportCheck } from "react-use-webp-support-check";
import { useMediaQuery } from 'react-responsive';
import Header from "../Header"
import Footer from "../Footer"
import "../../styles/main.scss"
const Layout = props => {
  // const data = useStaticQuery(graphql`
  //   query SiteEnTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  const isWebPsupported = useWebPSupportCheck();
  const isMobile = useMediaQuery({ query: '(max-width: 816px)' });

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [scrollerAtBottom, setScrollerAtBottom] = React.useState(false);
  const [scrolledUp, setScrolledUp] = React.useState(false);
  const [scrolled, setScrolled] = React.useState(false);
  const [showScrollToTopBtn, setShowScrollToTopBtn] = React.useState(false);
  const [isToggleNavBar, setToggleNavBar] = React.useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({top:0, behavior:'smooth'})
  }

  const handleScroll = () => {
    const offset=window.scrollY;
    const position = window.pageYOffset;

    // Fixed Header
    if(!isMobile){
      if(offset > 300 ){
        if(!scrolled){setScrolled(true)}
      }
      else{
        if(scrolled){setScrolled(false)}
      }
    }else{

      // setScrolledPosition for Mobile
      if(position < scrollPosition){
        if(!scrolledUp){
          setScrolledUp(true);
        }
      }else{
        if(scrolledUp){
          setScrolledUp(false);
        }
      }
      setScrollPosition(position);
    }

   // ScrollToTopBtn
   if (!showScrollToTopBtn && window.pageYOffset > 400){
      setShowScrollToTopBtn(true)
    } else if (showScrollToTopBtn && window.pageYOffset <= 400){
      setShowScrollToTopBtn(false)
    }

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      if(!scrollerAtBottom){
        setScrollerAtBottom(true)
      }else{
        setScrollerAtBottom(false)
      }
    }else{
      if(scrollerAtBottom){
        setScrollerAtBottom(false)
      }
    }

  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  })

  let mainWrapperClasses = ['main-wrapper'];
  let headerClasses = ['header'];
  let scrollToTopBtnClasses = ['scroll-to-top-btn'];
  let mobileFooterClasses = ['footer-mobile', 'footer-fixed'];

  // For MainWrapper
  if(isToggleNavBar){
    mainWrapperClasses.push('menu-isopened');
  }

  if(isWebPsupported){
    mainWrapperClasses.push('webp-supported');
  }

  // For Header
  if(scrolled){
    headerClasses.push('header-fixed');
  }

  // For Footer & ScrollToTop
  if(scrolledUp){
    mobileFooterClasses.push('show');
  }else{
    if(showScrollToTopBtn){
      scrollToTopBtnClasses.push('show');
    }
  }

  // For Footer at Bottom of Page
  if(isMobile && scrollerAtBottom){
    mobileFooterClasses.push('show');
    scrollToTopBtnClasses.push('show mobile');
  }

  if(isMobile && window.pageYOffset < 100){
    mobileFooterClasses.push('show');
  }

  return (
      <div className={mainWrapperClasses.join(" ")}>
        {/* <!-- HEADER --> */}
          <Header
            headerClasses={headerClasses}
            setToggleNavBar={() => setToggleNavBar(!isToggleNavBar)}
            isToggleNavBar={isToggleNavBar}
          />
          <main>{props.children}</main>
          {/* <!-- Footer --> */}
          <div className={scrollToTopBtnClasses.join(" ")} onClick={handleScrollToTop}>
          </div>
          <Footer mobileFooterClasses={mobileFooterClasses.join(" ")}/>

      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout