import PropTypes from "prop-types"
import React from "react"
import logoImg from "../../images/logo.svg"
import logoWhiteImg from "../../images/logo-white.svg"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { globalHistory } from "@reach/router"
import { siteData } from "../../data/siteData"
const Header = props => {
  const { isToggleNavBar, headerClasses } = props;
  const path = globalHistory.location.pathname.split("/")

  return (
    <React.Fragment>
      <header className={headerClasses.join(" ")}>
        <div className="container">
          <div className="row">
            <div
              className={
                isToggleNavBar
                  ? "header-flex flex mobile-menu-open"
                  : "header-flex flex"
              }
            >
              <div className="header-cols head-col-left">
                <Link to="/" className="logo-wrapper" rel="noreferrer">
                  <img src={logoImg} alt="AEH" className="globel-logo" />
                  <img
                    src={logoWhiteImg}
                    alt="AEH"
                    className="mobile-menu-icon"
                  />
                </Link>
              </div>
              <div className="header-cols head-col-center flex-grow-1">
                <div id="desktopnav" className="desk-nav">
                  <div className="nav-inner topnav">
                    <ul className="nav">
                      <li className="nav-item">
                        <Link
                          to="/"
                          rel="noreferrer"
                          className={
                            path[1] === "" ? "nav-link active" : "nav-link"
                          }
                        >
                          ホーム
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/about-us"
                          rel="noreferrer"
                          className={
                            path[1] === "about-us"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          スクールについて
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/classes"
                          rel="noreferrer"
                          className={
                            path[1] === "classes"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          クラス
                        </Link>
                      </li>
                      <li className="nav-item">
                        <OutboundLink
                          href="https://sites.google.com/view/aeh-student-site/home"
                          className="nav-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                            学生向けリソース
                        </OutboundLink>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/access"
                          rel="noreferrer"
                          className={
                            path[1] === "access"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          アクセス
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/contact-us"
                          rel="noreferrer"
                          className={
                            path[1] === "contact-us"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          お問い合わせ
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="hide-only-desktop social-content">
                    <ul className="social-wrapper social-white container-fluid text-center">
                      {siteData.site.sns.instagram !== "" &&(
                        <li className="social-item">
                          <a
                            href={siteData.site.sns.instagram}
                            target="_blank"
                            className="icon-link instagram"
                            rel="noreferrer"
                            role="link"
                            aria-label="Instagram"
                          >
                            <i className="icon icon-instagram-red" />

                          </a>
                        </li>
                      )}

                      {siteData.site.sns.facebook !== "" &&(
                        <li className="social-item">
                          <a
                            href={siteData.site.sns.facebook}
                            target="_blank"
                            className="icon-link facebook"
                            rel="noreferrer"
                            role="link"
                            aria-label="Facebook"
                          >
                            <i className="icon icon-facebook-red" />
                          </a>
                        </li>
                      )}

                      {siteData.site.sns.twitter !== "" &&(
                        <li className="social-item">
                          <a
                            href={siteData.site.sns.twitter}
                            target="_blank"
                            className="icon-link twitter"
                            rel="noreferrer"
                            role="link"
                            aria-label="Twitter"
                          >
                            <i className="icon icon-twitter-red" />
                          </a>
                        </li>
                      )}

                      {siteData.site.sns.youtube !== "" &&(
                        <li className="social-item">
                          <a
                            href={siteData.site.sns.youtube}
                            target="_blank"
                            className="icon-link youtube"
                            rel="noreferrer"
                            role="link"
                            aria-label="YouTube"
                          >
                            <i className="icon icon-youtube-red" />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="mob-btn-wrapper">
                  <button
                    className="c-hamburger c-hamburger--htx"
                    onClick={props.setToggleNavBar}
                  >
                    <span>toggle menu</span>
                  </button>
                </div>
              </div>
              <div className="header-cols head-col-right hide-only-mobile">
                <Link to="/contact-us" className="btn btn-secondary btn-icon icon-right">
                  <div className="btn-inner">
                    <div className="btn-text">無料体験レッスン</div>
                    <i className="btn-icon-right icon icon-arrow-right" aria-hidden="true" />
                  </div>

                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
