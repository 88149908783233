export const siteData = {
  site: {
    title: {
      en: "American English School",
      ja: "アメリカンイングリッシュハウス"
    },
    map: {
      zoom: 8,
      lat: 40.854885,
      lng: -88.081807,
      url: "https://www.google.com/maps/dir//35.1661673,136.8202231/@35.168202,136.820051,14z?hl=ja"
    },
    address: "愛知県海部郡大治町三本木屋形147",
    addressPostCode: "〒490-1142",
    addressDirections: "(大治南小学校東門の目の前にある白い３階建て)",
    siteUrl: `https://americanenglishhouse.jp`,
    description: `American English School`,
    author: `@redcranejapan`,
    contactEmail: `info@americanenglishhouse.jp`,
    contactPhone: `052-442-7807`,
    sns: {
      facebook: "https://www.facebook.com/American-English-House-123419444427014",
      twitter: "",
      instagram: "",
      youtube: ""
    }
  },
  homePage: {
    title: "子ども英会話 あま市大治町 中川区 - American English House",
    description:
      "あま市大にあるアメリカンイングリッシュハウスは海部郡のこども向け子ども英会話スクールで少人数制で楽しく分かりやすい指導を行っております。園児・小学生・中高生・大人クラスがあり幅広い年齢に対応しており、大治町、あま市甚目寺町や中川区から多く通っています。",
    keywords: "",
    image: "/sns/home.jpg",
    path: "/"
  },
  classesPage: {
    title: "園児から小学生英会話スクール 大治 - American English House",
    description:
      "アメリカンイングリッシュハウスは園児・小学生・中高生・大人のそれぞれ4つのクラスがある英会話スクールです。英語の学習経験やレベルに合わせたクラスを選択可能。どのクラスも年度途中の入会が可能。振替レッスンもございます。大治町からはもちろん甚目寺・中川区から多くの方が通っています。",
    keywords: "",
    image: "/sns/classes.jpg",
    path: "/classes"
  },
  aboutusPage: {
    title: " 海部郡大治　人気の英会話スクール・習い事ならAEH",
    description:
      "アメリカンイングリッシュハウス（AEH）では世界でのコミュニケーションツールとしての英語を楽しく効率的に習得することを目指しています。ネイティブ講師の利点を生かしたカリキュラムでレベルアップをサポート。子ども達のやる気を育みます。",
    keywords: "",
    image: "/sns/about-us.jpg",
    path: "/about-us"
  },
  accessPage: {
    title: "アメリカンイングリッシュハウス 海部郡大治町三本木屋形147",
    description: "子ども英会話　子供英会話　愛知県海部郡大治町三本木屋形147",
    keywords: "",
    image: "/sns/access.jpg",
    path: "/access"
  },
  contactUsPage: {
    title: "子ども英会話　楽しい無料体験あり - American English House",
    description:
      "アメリカンイングリッシュハウスはあま市大治町の楽しい子ども英会話教室。小学生・中高生・大人のクラスもございます。ネイティブ講師が分かりやすく指導します。楽しく効率よく英語を習得しませんか？ 無料体験実施中。お気軽にお問い合わせください。",
    keywords: "",
    image: "/sns/contact-us.jpg",
    path: "/contact-us"
  },
  errorPage: {
    title: "404: Not found",
    description: "404: Not found",
  }
}

/*:
  homePage: {
    title: "あま市大治町の子ども英会話 中川区 - American English House",
    description:
      "アメリカンイングリッシュハウスは海部郡のこども向け子ども英会話スクールで少人数制で楽しく分かりやすい指導を行っております。園児・小学生・中高生・大人クラスがあり幅広い年齢に対応しており、大治町、あま市甚目寺町や中川区から多く通っています。",
    keywords: "",
    image: "/sns/home.jpg",
    path: "/"
  },
 */