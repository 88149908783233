/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ metaData, siteData, lang }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            siteUrl
            author
          }
        }
      }
    `
  )

  return (
    <Helmet
      title={metaData.title}
      description={metaData.description}
      // image={logo}
      htmlAttributes={{
        lang: lang
      }}
      link={[
        {
          rel: `canonical`,
          href: `${siteData.site.siteUrl}${metaData.path}`,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaData.description,
        },
        {
          property: `og:url`,
          content: `${siteData.site.siteUrl}${metaData.path}`,
        },
        {
          property: `og:title`,
          content: metaData.title,
        },
        {
          property: `og:description`,
          content: metaData.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${siteData.site.siteUrl}${metaData.image}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: `@americanenglishhouse`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaData.title,
        },
        {
          name: `twitter:description`,
          content: metaData.description,
        },
      ].concat(metaData)}
    />
  )
}

SEO.defaultProps = {
  lang: `ja`,
  metaData: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  //metaData: PropTypes.arrayOf(PropTypes.object),
  // title: PropTypes.string.isRequired,
}

export default SEO