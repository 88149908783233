import React from "react"
import logoWhiteImg from "../../images/logo-white.svg"
import { Link } from "gatsby"
import { siteData } from "../../data/siteData"

const Footer = ({mobileFooterClasses}) => {
  // const data = useStaticQuery(graphql`
  //   query MyFooterQuery {
  //     footerDesktop: file(relativePath: { eq: "footer-bg.jpg" }) {
  //       childImageSharp {
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      {/* <!-- Footer --> */}
      <footer className="bg-darkblue footer">
        <div id="footer-desktop" className="footer-desktop inner-padding">
          <div className="container">
            <div className="row">
              <div className="footer-cols footer-left">
                <div className="row">
                  <div className="col-sm-6 footer-left-cols">
                    <div className="footer-logo foot-hide-mobile">
                      <img src={logoWhiteImg} className="flogo" alt="flogo" />
                    </div>
                    <div className="footer-social">
                      <ul className="social-wrapper">

                        {siteData.site.sns.instagram !== "" &&(
                          <li className="social-item">
                            <a
                              href={siteData.site.sns.instagram}
                              target="_blank"
                              className="icon-link instagram"
                              rel="noreferrer"
                              role="link"
                              aria-label="Instagram"
                            >
                              <i className="icon icon-instagram" />

                            </a>
                          </li>
                        )}

                        {siteData.site.sns.facebook !== "" &&(
                          <li className="social-item">
                            <a
                              href={siteData.site.sns.facebook}
                              target="_blank"
                              className="icon-link facebook"
                              rel="noreferrer"
                              role="link"
                              aria-label="Facebook"
                            >
                              <i className="icon icon-facebook" />
                            </a>
                          </li>
                        )}

                        {siteData.site.sns.twitter !== "" &&(
                          <li className="social-item">
                            <a
                              href={siteData.site.sns.twitter}
                              target="_blank"
                              className="icon-link twitter"
                              rel="noreferrer"
                              role="link"
                              aria-label="Twitter"
                            >
                              <i className="icon icon-twitter" />
                            </a>
                          </li>
                        )}

                        {siteData.site.sns.youtube !== "" &&(
                          <li className="social-item">
                            <a
                              href={siteData.site.sns.youtube}
                              target="_blank"
                              className="icon-link youtube"
                              rel="noreferrer"
                              role="link"
                              aria-label="YouTube"
                            >
                              <i className="icon icon-youtube" />
                            </a>
                          </li>
                        )}



                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 foot-hide-mobile">

                    <ul className="footer-contact">
                      <li className="fc-item">
                        <i className="icon icon-location" />
                        <div>
                          <div>{siteData.site.addressPostCode}</div>
                          <div>{siteData.site.address}</div>
                        </div>
                      </li>
                      <li className="fc-item">
                        <a
                          className="link-white"
                          href={`mailto:${siteData.site.contactEmail}`}
                          role="link"
                        >
                          <i className="icon icon-envelope" />
                          {siteData.site.contactEmail}
                        </a>
                      </li>
                      <li className="fc-item">
                        <a className="link-white" href={`tel:${siteData.site.contactPhone}`} role="link">
                          <i className="icon icon-call" />
                          {siteData.site.contactPhone}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-cols footer-right foot-hide-mobile">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="footer-links">
                      <li className="flink-item">
                        <Link to="/about-us" className="flink link-white">
                          AEHについて
                        </Link>
                      </li>
                      <li className="flink-item">
                        <Link to="/access" className="flink link-white">
                          アクセス
                        </Link>
                      </li>
                      <li className="flink-item">
                        <Link to="/classes" className="flink link-white">
                          クラス
                        </Link>
                        <ul className="inner-links">
                          <li className="inner-link-item">
                            <Link
                              to="/classes#childrens-class"
                              className="link-white"
                            >
                              園児クラス
                            </Link>
                          </li>

                          <li className="inner-link-item">
                            <Link
                              to="/classes#elementry-class"
                              className="link-white"
                            >
                              小学生クラス
                            </Link>
                          </li>

                          <li className="inner-link-item">
                            <Link
                              to="/classes#junior-class"
                              className="link-white"
                            >
                              中高生クラス
                            </Link>
                          </li>
                          <li className="inner-link-item">
                            <Link
                              to="/classes#adult-class"
                              className="link-white"
                            >
                              大人クラス
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="flink-item">
                        <Link to="/contact-us" className="flink link-white">
                          お問い合わせ
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer-mobile" className={mobileFooterClasses}>
          <div className="container-fluid">
            <div className="row fm-links-wrapper">
              <div className="fm-link-item">
                <Link href={`mailto:${siteData.site.contactEmail}`} className="fm-link">

                  <i className="icon icon-envelope" />
                  メール
                </Link>
              </div>
              <div className="fm-link-item center-item">
                <Link  href={`mailto:${siteData.site.contactPhone}`} className="fm-link">
                  <i className="icon icon-call" />
                  電話
                </Link>
              </div>
              <div className="fm-link-item">
                <Link to="/contact-us#contact-form" className="fm-link">
                  <i className="icon icon-x2 icon-booktrial" />
                  無料体験
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <!-- Copyright --> */}
      <section className="footer-bar-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-left copyright-text">
            </div>
            <div className="col-md-4 text-center copyright-text">
              © Copyright {new Date().getFullYear()}{" "}
              <Link to="/" className="link-white">
                American English House
              </Link>
              . All rights reserved
            </div>
            <div className="col-md-4 built-by-text">
              Built By{" "}
              <a href={'https://redcranesolutions.com'} target="_blank" className="link-white" role="link" rel="noopener">
                RED CRANE
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer


/*
<ul className="row fm-links-wrapper">
              <li className="col-xs-4 fm-link-item">
                <Link href="mailto:mawadima@yahoo.co.jp" className="fm-link">
                  <i className="icon icon-envelope" />
                  メールでのお問い合わせ
                </Link>
              </li>
              <li className="col-xs-4 fm-link-item">
                <Link href="tel:052-442-7807" className="fm-link">
                  <i className="icon icon-call" />
                  電話でのお問い合わせ
                </Link>
              </li>
              <li className="col-xs-4 fm-link-item">
                <Link to="/contact-us#contact-form" className="fm-link">
                  <i className="icon icon-x2 icon-booktrial" />
                  無料体験レッスンを予約
                </Link>
              </li>
            </ul>
 */